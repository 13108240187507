const catalogSections = document.querySelectorAll('.catalog-section');

catalogSections.forEach((section) => {
  const items = section.querySelectorAll('.catalog-section__item');
  items.forEach((item) => {
    const imgBox = section.querySelector('.catalog-section__detail-img img');
    const heightBox = section.querySelector('.catalog-section__detail-right .catalog-section__detail-number');
    const widthBox = section.querySelector('.catalog-section__detail-bottom .catalog-section__detail-number');

    const heightBoxLine = section.querySelector('.catalog-section__detail-right');
    const widthBoxLine = section.querySelector('.catalog-section__detail-bottom');


    const nameBox = section.querySelector('.catalog-section__info-item.is-name .catalog-section__info-item-value');
    const nominalVolumeBox = section.querySelector(
      '.catalog-section__info-item.is-nominal-volume .catalog-section__info-item-value'
    );
    const fullVolumeBox = section.querySelector(
      '.catalog-section__info-item.is-full-volume .catalog-section__info-item-value'
    );
    const coverTypeBox = section.querySelector(
      '.catalog-section__info-item.is-cover-type .catalog-section__info-item-value'
    );
    const countBox = section.querySelector('.catalog-section__info-item.is-count .catalog-section__info-item-value');
    const packagingTypeBox = section.querySelector(
      '.catalog-section__info-item.is-packaging-type .catalog-section__info-item-value'
    );

    item.addEventListener('click', () => {
      section.querySelector('.catalog-section__item.is-active').classList.remove('is-active');
      item.classList.add('is-active');
      const img = item.querySelector('.catalog-section__item-img img');
      const name = item.dataset.name;
      const nominalVolume = item.dataset.nominalVolume;
      const fullVolume = item.dataset.fullVolume;
      const coverType = item.dataset.coverType;
      const count = item.dataset.count;
      const packagingType = item.dataset.packagingType;
      const width = item.dataset.width;
      const height = item.dataset.height;

      if(height) {
        heightBox.innerHTML = height;
        heightBoxLine.classList.remove("hidden");
      } else {
        heightBoxLine.classList.add("hidden");
      }

      if(width) {
        widthBox.innerHTML = width;
        widthBoxLine.classList.remove("hidden");
      } else {
        widthBoxLine.classList.add("hidden");
      }

      nameBox.innerHTML = name;
      nominalVolumeBox.innerHTML = nominalVolume;
      fullVolumeBox.innerHTML = fullVolume;
      coverTypeBox.innerHTML = coverType;
      countBox.innerHTML = count;
      packagingTypeBox.innerHTML = packagingType;

      imgBox.src = img.src;
    });
  });
});
