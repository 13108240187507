import { gsap } from 'gsap';

var timeout;
const qualitysection = document.querySelector('.quality-section');
if (qualitysection) {
  qualitysection.addEventListener('mousemove', (e) => {
    if (timeout) clearTimeout(timeout);
    setTimeout(callParallax.bind(null, e), 200);
  });
}

function callParallax(e) {
  parallaxIt(e, '.quality-section__jar img', 30);
  parallaxIt(e, '.quality-section__jar-glow .decor-inner', -30);
}

function parallaxIt(e, target, movement) {
  var $this = qualitysection;
  var relX = e.pageX - $this.offsetLeft;
  var relY = e.pageY - $this.offsetTop;
  gsap.to(target, {
    translateX: ((relX - $this.offsetWidth / 2) / $this.offsetWidth) * movement,
    translateY: ((relY - $this.offsetHeight / 2) / $this.offsetHeight) * movement,
    duration: 2.5,
    ease: 'power4.out',
  });
}
