import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const header = document.querySelector('.header');
const headerBurger = document.querySelector('.header__mobile-menu-button');
const headerMobile = document.querySelector('.heder-mobile-menu');
const headerBg = document.querySelector('.header-bg');

headerBurger.addEventListener('click', () => {
  if (headerMobile.classList.contains('is-active')) {
    headerBurger.classList.remove('is-active');
    headerMobile.classList.remove('is-active');
    headerBg.classList.remove('is-active');
    window.startLenis();
  } else {
    headerBurger.classList.add('is-active');
    headerMobile.classList.add('is-active');
    headerBg.classList.add('is-active');
    window.stopLenis();
  }
});

headerBg.addEventListener('click', () => {
  headerMobile.classList.remove('is-active');
  headerBg.classList.remove('is-active');
});

gsap.to('body', {
  scrollTrigger: {
    trigger: 'body',
    start: 'top+=20px top ',
    end: `bottom bottom`,
    onEnter: () => {
      header.classList.add('is-fixed');
    },
    onLeaveBack: () => {
      header.classList.remove('is-fixed');
    },
  },
});
