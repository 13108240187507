import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

const sertSlider = document.querySelectorAll('.sert-section__slider');
sertSlider.forEach((el) => {
  const slider = el.querySelector('.swiper');
  const swiper = new Swiper(slider, {
    slidesPerView: 'auto',
    spaceBetween: 20,
    loop: false,
  });
});
