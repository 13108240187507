import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

const aboutSlider = document.querySelectorAll('.about-section__slider');
aboutSlider.forEach((el) => {
  const slider = el.querySelector('.swiper');
  const swiper = new Swiper(slider, {
    slidesPerView: 'auto',
    spaceBetween: 20,
    loop: false,
  });
});
