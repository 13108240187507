import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
/* Base scripts */

/* Проверка на safari */
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
if (isSafari) {
  document.querySelector('html').classList.add('is-safari');
}

/* Проверка на ios */
function isIpad() {
  if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
    return true;
  }
}
function isIOS() {
  return isIpad() || /iPad|iPhone|iPod/.test(navigator.userAgent);
}

function isMac() {
  return navigator.userAgent.match(/Mac/);
}

if (isIOS() || isMac()) {
  document.querySelector('html').classList.add('is-OSX');
}

/* Проверка на моб девайс */
const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    navigator.userAgent
  ) || isIOS();
if (isMobile) {
  document.querySelector('html').classList.add('is-mobile');
}

/* Start fansybox */
Fancybox.bind('[data-fancybox]', {
  Hash: false,
});

gsap.registerPlugin(ScrollTrigger);

window.startLenis = () => {
  document.querySelector('html').classList.remove('noskroll');
  document.querySelector('body').classList.remove('noskroll');
};

window.stopLenis = () => {
  document.querySelector('html').classList.add('noskroll');
  document.querySelector('body').classList.add('noskroll');
};

/* navigation */
const navLinks = document.querySelectorAll('.js-navigation-link');
navLinks.forEach((el) => {
  el.addEventListener('click', (e) => {
    e.preventDefault();
    const id = el.getAttribute('href')?.slice(1);
    if (!id) return;
    const offset = el.dataset.headerOffset ? 0 - document.querySelector('.header').offsetHeight : 0;
    const target = id === 'start' ? document.querySelector('body') : document.getElementById(id);
    const y = target.getBoundingClientRect().top + window.scrollY + offset;
    window.scrollTo({top: y, behavior: 'smooth'});
  });
});

/* content fade-in animation */
const fadeInContent = document.querySelectorAll('.fade-in-content');
fadeInContent.forEach((el) => {
  gsap.to(el, {
    scrollTrigger: {
      trigger: el,
      start: 'top bottom-=50px',
      end: `bottom top`,
      onEnter: () => {
        el.classList.add('is-animated');
      },
    },
  });
});

/* Accordion */
(() => {
  const accordionItems = document.querySelectorAll('.accordion-item');
  accordionItems.forEach((el) => {
    const header = el.querySelector('.accordion-item__header');
    const content = el.querySelector('.accordion-item__content');
    if (!header && !content) return false;
    header.addEventListener('click', () => {
      if (header.classList.contains('is-active')) {
        content.classList.remove('is-active');
        header.classList.remove('is-active');
        setTimeout(() => {
          content.style.maxHeight = '0';
        }, 0);
      } else {
        header.classList.add('is-active');
        content.style.maxHeight = content.scrollHeight + 'px';
        setTimeout(() => {
          content.classList.add('is-active');
        }, 300);
      }
    });
  });
})();

/* Start phone mask */

import { MaskInput } from 'maska';

(() => {
  const maskList = [
    {
      name: 'Belarus',
      code: '+375',
      iso: 'BY',
      flag: '/local/templates/main/app/src/img/flag-by.png',
      mask: '(##)###-##-##',
    },
    {
      name: 'Russia',
      code: '+7',
      iso: 'RU',
      flag: '/local/templates/main/app/src/img/flag-ru.png',
      mask: '(###)###-##-##',
    },
  ];

  const phoneInputs = document.querySelectorAll('.phone-input');

  phoneInputs.forEach((el) => {
    const input = el.querySelector('.input');
    const dropdown = el.querySelector('.phone-input__mask-switch-dropdown');
    const current = el.querySelector('.phone-input__mask-switch-current');
    let mask = null;
    function setMask(maskItem) {
      mask = new MaskInput(input, {
        mask: `${maskItem.code}${maskItem.mask}`,
        eager: true,
        onMaska: (detail) => {
          if (detail.completed) {
            el.classList.add('is-valid');
          } else {
            el.classList.remove('is-valid');
          }
        },
      });
    }
    setMask(maskList[0]);

    maskList.forEach((item) => {
      var div = document.createElement('div');
      div.innerHTML = `<div class="phone-input__dropdown-item"><img src="${item.flag}" class="phone-input__main-icon"></div>`;
      dropdown.appendChild(div);

      div.addEventListener('click', (e) => {
        current.innerHTML = `
          <img src="${item.flag}" class="phone-input__main-icon">
          <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L5 5L9 1" stroke="white"/>
          </svg>
        `;
        input.value = '+';
        mask.destroy();
        setMask(item);
      });
    });
    current.innerHTML = `
      <img src="${maskList[0].flag}" class="phone-input__main-icon">
      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L5 5L9 1" stroke="white"/>
      </svg>
    `;

    current.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      dropdown.classList.toggle('is-active');
    });

    input.addEventListener('focus', () => {
      if (!input.value) {
        input.value = '+';
      }
    });
  });

  document.addEventListener('click', (e) => {
    if (!e.target.closest('.phone-input__mask-switch')) {
      document
        .querySelectorAll('.phone-input__mask-switch-dropdown.is-active')
        .forEach((el) => el.classList.remove('is-active'));
    }
  });
})();


/* FORM VALIDATE*/
document.addEventListener('click', (event) => {
  if (event.target.classList.contains('js-form-submit')) {
    const form = event.target.closest('.js-validate-form')
    let errors = 0;
      const fields = form.querySelectorAll(".form-input");
      fields.forEach((field) => {
        const input = field.querySelector(".input");
        if (input) {
          const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          if (
            field.classList.contains("is-checkbox") &&
            field.classList.contains("is-required") &&
            !input.checked
          ) {
            errors++;
            field.classList.add("is-error");
          } else if (
            field.classList.contains("is-email") &&
            input.value &&
            !input.value.match(mailformat)
          ) {
            errors++;
            field.classList.add("is-error");
          } else if (
            field.classList.contains("is-required") &&
            field.classList.contains("is-phone") &&
            !field.classList.contains("is-valid")
          ) {
            errors++;
            field.classList.add("is-error");
          } else if (field.classList.contains("is-required") && !input.value) {
            errors++;
            field.classList.add("is-error");
          }
        }
      });

      if (errors) {
        event.preventDefault();
        return false;
      }
  }
});


function clearForm(el) {
  const fields = el.querySelectorAll('.input');
  fields.forEach((field) => {
    field.value = '';
  });

  const fileInputs = el.querySelectorAll('.file-input');
  fileInputs.forEach((field) => {
    if (field && field.classList.contains('is-active')) {
      field.click();
    }
  });
}

const fields = document.querySelectorAll('.js-validate-form .form-input');
fields.forEach((field) => {
  const input = field.querySelector('.input');
  if (input) {
    input.addEventListener('input', function () {
      field.classList.remove('is-error');
    });
  }
});

import './import/modules';
import './import/components';
